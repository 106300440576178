import React from 'react';

import { useRouter } from 'next/router';

import { Head } from '../components';
import { Assets } from '../globals/configureAssets';
import { useVkwFormatMessage, useVkwAssets, VkwErrorView } from '../library';
import { MyNextPage } from '../types/next';

const Custom404Page: MyNextPage = () => {
  const formatMessage = useVkwFormatMessage();
  const assets = useVkwAssets<Assets>();
  const router = useRouter();

  return (
    <>
      <Head pageTranslationKey="ErrorTitle" />
      <VkwErrorView
        errorNumber={404}
        actualUrl={typeof window !== 'undefined' ? window.location.href : ''}
        helpfulLinks={[
          { onClick: () => router.push('/'), title: formatMessage('StartPage') },
          { href: assets.links.corporateWebsite, title: formatMessage('CorporateStartPage') },
          { onClick: router.back, title: formatMessage('Back') },
        ]}
      />
    </>
  );
};

export default Custom404Page;
